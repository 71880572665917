import Plyr from 'plyr'

const youtubeVideoDialogs = document.querySelectorAll("dialog.video-iframe");
const fileVideoDialogs = document.querySelectorAll("dialog.video-file");

if(youtubeVideoDialogs.length > 0) {
  // Load YouTube API first
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  youtubeVideoDialogs.forEach((dialog) => {
    let player = null;
    const videoHolder = dialog.querySelector('.video-holder');
    const videoHtml = atob(dialog.getAttribute('data-video-html'));
    
    // Handle popover open/close with single beforetoggle event
    dialog.addEventListener('beforetoggle', (event) => {
      if (event.newState === 'open') {
        // Only initialize video if it hasn't been already
        if (!player) {
          // Insert video HTML
          videoHolder.innerHTML = videoHtml;
          const videoEl = videoHolder.querySelector('iframe');
          
          if (!videoEl) {
            console.error('No video iframe found in dialog');
            return;
          }
          
          // Get video ID from YouTube URL
          const videoUrl = videoEl.src;
          const videoId = videoUrl.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i)?.[1];
          
          if (!videoId) {
            console.error('Could not extract YouTube video ID');
            return;
          }

          // Create wrapper div with data-plyr-provider
          const wrapper = document.createElement('div');
          wrapper.className = 'plyr__video-embed';
          wrapper.setAttribute('data-plyr-provider', 'youtube');
          wrapper.setAttribute('data-plyr-embed-id', videoId);
          
          // Replace iframe with wrapper
          videoEl.parentNode.replaceChild(wrapper, videoEl);
          wrapper.appendChild(videoEl);
          
          try {
            player = new Plyr(wrapper, {
              controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
              youtube: {
                noCookie: true,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                modestbranding: 1
              }
            });
            
            player.on('ready', () => {
              console.log('Plyr is ready');
              setTimeout(() => player.play(), 100);
            });
            
            player.on('error', (error) => {
              console.error('Plyr error:', error);
            });
          } catch (error) {
            console.error('Error initializing Plyr:', error);
          }
        } else {
          // Video already initialized, just play it
          setTimeout(() => player.play(), 100);
        }
      } else if (event.newState === 'closed') {
        // Pause video when closing
        if (player) {
          player.pause();
        }
      }
    });
  });
}

if (fileVideoDialogs.length > 0) {
fileVideoDialogs.forEach((dialog) => {
  dialog.addEventListener('beforetoggle', (event) => {
    const video = dialog.querySelector('video');
    if (event.newState === 'open') {
      setTimeout(() => video.play(), 100);
    } else if (event.newState === 'closed') {
      video && video.pause();
    }
  });
});
}
