import Splide from "@splidejs/splide";

export default () => ({
  slider: null,
  isDesktop: window.matchMedia('(min-width: 1024px)'),

  init() {
    if(this.isDesktop.matches) {
      this.slider && this.slider.destroy();
      this.removeSplideClasses();
    } else {
      this.addSplideClasses();
      this.createSlider();
    }

    this.isDesktop.addEventListener('change', this.refresh.bind(this));
  },

  refresh() {
    if (this.isDesktop.matches) {
      this.slider && this.slider.destroy();
      this.removeSplideClasses();
    } else {
      this.addSplideClasses();
      this.createSlider();
    }
  },

  addSplideClasses() {
    this.$refs.slider.classList.add('splide');
    this.$refs.track.classList.add('splide__track');
    this.$refs.list.classList.add('splide__list');
    this.$refs.list.querySelectorAll('[data-slide]').forEach((slide) => {
      slide.classList.add('splide__slide');
    });
  },

  removeSplideClasses() {
    this.$refs.slider.classList.remove('splide');
    this.$refs.track.classList.remove('splide__track');
    this.$refs.list.classList.remove('splide__list');
    this.$refs.list.querySelectorAll('[data-slide]').forEach((slide) => {
      slide.classList.remove('splide__slide');
    });
  },

  createSlider() {
    this.slider = new Splide(this.$refs.slider, {
      type: "loop",
      perMove: 1,
      perPage: 1,
      gap: 20,
      gap: 24,
      pagination: false,
      arrows: false,
      drag: true,
      autoWidth: true,
      mediaQuery: 'min',
      breakpoints: {
        768: {
          gap: 24
        },
      }
    }).mount();
  }
});
