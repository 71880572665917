export default ({ url }) => ({
  fetchUrl: url,
  activeMenu: false,
  searchOpen: false,
  searchResults: [],
  query: "",
  menuOpen: false,
  mql: window.matchMedia("(min-width: 1100px)"),
  isDesktop: false,

  init() {
    this.mql.addEventListener('change', this.resizeEvent.bind(this));
    this.resizeEvent();
  },

  resizeEvent() {
    this.isDesktop = this.mql.matches;
    this.menuOpen = this.mql.matches;
    this.overflowClass();
  },

  overflowClass() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");

    if (this.menuOpen && window.innerWidth <= 1100) {
      html.classList.add("overflow-hidden");
      body.classList.add("overflow-hidden");
    } else {
      html.classList.remove("overflow-hidden");
      body.classList.remove("overflow-hidden");
    }
  },

  async handleSearch() {
    // Reset
    this.searchResults = [];
    this.$refs.results.innerHTML = "";

    await this.fetchResults();

    if (this.searchResults.length) {
      this.searchResults.forEach((result, index) => {
        this.handleResult({ ...result });
      });
    }
  },

  async fetchResults() {
    try {
      const formData = new FormData();
      formData.append("action", "load_search_results");
      formData.append("query", this.query);

      const response = await fetch(`${this.fetchUrl}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();
      this.searchResults = json;
    } catch (error) {
      console.error(error.message);
    }
  },

  handleResult({ url, title, image, tag }) {
    const template = document.querySelector("#searchresult");
    const clone = template.content.cloneNode(true);

    const imageHolder = clone.querySelector("[data-image]");
    const imageWrapper = clone.querySelector("[data-image-wrapper]");

    if (image.src != false) {
      imageHolder.src = image.src;
      imageHolder.srcset = image.srcset;
      imageWrapper.classList.add('block');
      this.mql.matches && imageWrapper.classList.remove('hidden');
    } else {
      imageWrapper.classList.add('hidden');
      imageWrapper.classList.remove('block');
    }

    clone.querySelector("[data-href]").href = url;
    const decodedTitle = new DOMParser().parseFromString(title, 'text/html').body.textContent;
    clone.querySelector("[data-heading]").textContent = decodedTitle;
    clone.querySelector("[data-tag]").textContent = tag || 'News';

    this.$refs.results.append(clone);
  },
});
